import React from 'react';
import { displayName, displayNameKana, Owner } from '~/models/owner';
import { seisanKanrikunContractNew } from '~/utils/foreignOrigin';
import { ContractWithOwners } from '~/models/contract';
import {
  convertDateWithNull,
  convertQueryStringDate,
  convertDisplayDate
} from '~/utils/dateUtils';
import { Modal, Alert } from '@itandi/itandi-bb-ui';
import { useContractCollaborationData } from '~/hooks/api/useContractCollaborationData';
import { ContractCollaborationData } from '~/models/contractCollaborationData';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
  contract: ContractWithOwners;
}>;

export const ContractsSeisanKanrikunCollaborationDataDisplayModal: React.FC<
  Props
> = ({ isOpen, handleClose, contract }: Props) => {
  const { data: contractCollaborationData } =
    useContractCollaborationData().useShow(contract.id);

  const buildSeisanKanrikunContractNewUrl = (
    contractWithOwners: DeepReadonly<ContractWithOwners>,
    collaborationData: DeepReadonly<ContractCollaborationData>
  ): string => {
    const startDate = convertDateWithNull(contractWithOwners.startDate);
    const endDate = convertDateWithNull(contractWithOwners.endDate);

    const ownerDebtTemplatesParams = collaborationData.goodsTitles.flatMap(
      (goodsTitle, index) => [
        `ykOwnerDebtTemplates[${index}][goodsTitleId]=${goodsTitle.skGoodsTitleId}`,
        `ykOwnerDebtTemplates[${index}][amount]=${goodsTitle.amount}`
      ]
    );

    const params = [
      `ykPropertyManagementId=${contractWithOwners.id}`,
      `ykPropertyName=${contractWithOwners.property.name}`,
      `ykContractKey=${contractWithOwners.originContract.contractKey}`,
      `kensakukunPropertyId=${contractWithOwners.property.kensakukunPropertyId}`,
      contractWithOwners.owners
        .map((owner: DeepReadonly<Owner>) => `ykOwnerIds[]=${owner.id}`)
        .join('&'),
      startDate ? `startDate=${convertQueryStringDate(startDate)}` : '',
      endDate ? `endDate=${convertQueryStringDate(endDate)}` : '',
      ...ownerDebtTemplatesParams
    ].filter((query: string) => query !== '');

    const queryString = params.join('&');
    return `${seisanKanrikunContractNew}?${queryString}`;
  };

  const redirectSeisanKanrikunContractNew = (): void => {
    if (!contractCollaborationData) return;

    const redirectUrl = buildSeisanKanrikunContractNewUrl(
      contract,
      contractCollaborationData
    );
    window.open(redirectUrl, '_blank');
    handleClose();
  };

  return (
    <Modal
      defaultFooterCancelText="キャンセル"
      handleClose={handleClose}
      handleSubmit={redirectSeisanKanrikunContractNew}
      isOpen={isOpen}
      size="large"
      submitLabel="連携確定"
      title="家主管理→精算管理へ管理委託契約連携"
      useDefaultFooter
      useDefaultFooterCancel
    >
      {contractCollaborationData && (
        <div className={Styles.Container}>
          <div className={Styles.OutlineArea}>
            <div className={Styles.Discription}>
              以下のデータは、精算管理くんの管理委託契約新規登録ページに遷移した時に連携されます。
            </div>
          </div>
          <div className={Styles.BasicContainer}>
            {contractCollaborationData.startDate && (
              <div className={Styles.BasicRow}>
                <div className={Styles.Label}>
                  <span className={Styles.LabelText}>契約開始日</span>
                </div>
                <div className={Styles.Content}>
                  {convertDisplayDate(contractCollaborationData.startDate)}
                </div>
              </div>
            )}

            {contractCollaborationData.endDate && (
              <div className={Styles.BasicRow}>
                <div className={Styles.Label}>
                  <span className={Styles.LabelText}>契約終了日</span>
                </div>
                <div className={Styles.Content}>
                  {convertDisplayDate(contractCollaborationData.endDate)}
                </div>
              </div>
            )}

            {contractCollaborationData.property && (
              <div className={Styles.BasicRow}>
                <div className={Styles.Label}>
                  <span className={Styles.LabelText}>管理対象の名前</span>
                </div>
                <div className={Styles.Content}>
                  {contractCollaborationData.property.displayName}
                </div>
              </div>
            )}
          </div>

          <div className={Styles.Title}>オーナー</div>
          {contractCollaborationData.owners.length > 0 && (
            <table className={Styles.TableContainer}>
              <thead className={Styles.TableHeader}>
                <tr className={Styles.TableHeaderRow}>
                  <th className={Styles.TableHeaderCell}>オーナー名</th>
                  <th className={Styles.TableHeaderCell}>
                    オーナー名のフリガナ
                  </th>
                </tr>
              </thead>
              {contractCollaborationData.owners.map((owner) => (
                <tbody>
                  <tr className={Styles.TableRow}>
                    <td className={Styles.TableData}>{displayName(owner)}</td>
                    <td className={Styles.TableData}>
                      {displayNameKana(owner)}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}

          {contractCollaborationData?.goodsTitles?.length > 0 && (
            <>
              <div className={Styles.Title}>費目</div>
              <div className={Styles.Discription}>
                設定に基づき、費目名が変換されます。
              </div>
              <Alert variant="warning">
                <div className={Styles.AlertInformationText}>
                  金額は税抜で変換されます。修正が必要な場合は、変換後に「精算管理」で修正してください。
                </div>
              </Alert>
              <table className={Styles.TableContainer}>
                <thead className={Styles.TableHeader}>
                  <tr className={Styles.TableHeaderRow}>
                    <th className={Styles.TableHeaderCell}>
                      家主管理くんでの費目名
                    </th>
                    <th className={Styles.TableHeaderCell}>金額(税抜)</th>
                    <th className={Styles.TableHeaderCell}>変換後の費目名</th>
                  </tr>
                </thead>
                <tbody>
                  {contractCollaborationData.goodsTitles.map((goodsTitle) => (
                    <tr className={Styles.TableRow}>
                      <td className={Styles.TableData}>{goodsTitle.name}</td>
                      <td className={Styles.TableData}>¥{goodsTitle.amount}</td>
                      <td className={Styles.TableData}>
                        {goodsTitle.skGoodsTitleName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
