import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { RiArticleLine } from 'react-icons/ri';
import { Input, InputSelect } from '@itandi/itandi-bb-ui';
import { ContractsEditorOwnerSelector } from '~/components/specifics/contracts/components/Editor/OwnerSelector';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertDateWithNull } from '~/utils/dateUtils';
import { EditorMode } from '~/components/common/Contracts/Editor';
import { ContractsEditorBuildingSelector } from '~/components/specifics/contracts/components/Editor/BuildingSelector';
import { ContractsEditorParkingSelector } from '~/components/specifics/contracts/components/Editor/ParkingSelector';
import { ContractsEditorSelectedProperty } from '~/components/specifics/contracts/components/Editor/SelectedProperty';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from './index.module.scss';

type Props = Readonly<{
  editorMode: EditorMode;
}>;

export const ContractsEditorBlock: React.FC<Props> = ({
  editorMode
}: Props) => {
  const { register, formState, setValue, watch } =
    useFormContext<ContractForEdit>();
  const { errors } = formState;
  const onChangeDate = React.useCallback(
    (
      date: Readonly<Date> | null,
      valueName: 'startDate' | 'endDate' | 'remittanceStartDate'
    ) => {
      if (date === null) {
        setValue(valueName, '');
        return;
      }
      setValue(valueName, date.toString());
    },
    [setValue]
  );

  const remoteProperty = watch('property.remoteProperty');
  const propertyType = watch('property.propertyType');
  const isDisabled = Boolean(remoteProperty?.kensakukunPropertyId);

  return (
    <FormBlock caption="契約情報" captionIcon={<RiArticleLine />}>
      <FormBlockRow
        errorMessage={errors.startDate?.message}
        label="契約開始日"
        required
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void =>
            onChangeDate(date, 'startDate')
          }
          selected={convertDateWithNull(watch('startDate'))}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.endDate?.message}
        label="契約終了日"
        required
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void =>
            onChangeDate(date, 'endDate')
          }
          selected={convertDateWithNull(watch('endDate'))}
        />
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.owners?.message} label="家主">
        <ContractsEditorOwnerSelector />
      </FormBlockRow>

      {editorMode !== 'renewal' && (
        <>
          <FormBlockRow
            errorMessage={
              errors.property?.remoteProperty?.kensakukunPropertyId?.message
            }
            label="管理対象"
          >
            <ContractsEditorSelectedProperty />
            <ContractsEditorBuildingSelector />
            <ContractsEditorParkingSelector />
          </FormBlockRow>

          <FormBlockRow
            errorMessage={errors.property?.name?.message}
            label="資産名"
            required
          >
            <Input
              disabled={isDisabled}
              isInvalid={errors.property?.name != null}
              placeholder="例）家主マンション"
              type="string"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`property.name`)}
            />
          </FormBlockRow>

          <FormBlockRow
            errorMessage={errors.property?.propertyType?.message}
            label="資産種別"
            required
          >
            <InputSelect
              disabled={isDisabled}
              isInvalid={errors.property?.propertyType != null}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('property.propertyType', {
                // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.target.value !== 'room') {
                    setValue('property.roomNumber', null);
                  }

                  if (e.target.value !== 'parking_space') {
                    setValue('property.parkingSpaceNumber', null);
                  }
                }
              })}
            >
              <option value="building">建物一棟</option>
              <option value="room">部屋</option>
              <option value="parking">駐車場全体</option>
              <option value="parking_space">駐車場区画</option>
            </InputSelect>
          </FormBlockRow>

          <FormBlockRow
            errorMessage={errors.property?.propertyDealType?.message}
            label="取引区分"
            required
          >
            <InputSelect
              disabled={isDisabled}
              isInvalid={errors.property?.propertyDealType != null}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`property.propertyDealType`)}
            >
              <option value="unspecified">未指定</option>
              <option value="rent">賃貸</option>
            </InputSelect>
          </FormBlockRow>

          {propertyType === 'room' && (
            <FormBlockRow
              errorMessage={errors.property?.roomNumber?.message}
              label="部屋番号"
            >
              <Input
                disabled={isDisabled}
                isInvalid={errors.property?.roomNumber != null}
                placeholder="例）101"
                type="string"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(`property.roomNumber`)}
              />
            </FormBlockRow>
          )}

          {propertyType === 'parking_space' && (
            <FormBlockRow
              errorMessage={errors.property?.parkingSpaceNumber?.message}
              label="駐車場の区画番号"
            >
              <Input
                disabled={isDisabled}
                isInvalid={errors.property?.parkingSpaceNumber != null}
                placeholder="例）101"
                type="string"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(`property.parkingSpaceNumber`)}
              />
            </FormBlockRow>
          )}
        </>
      )}

      <FormBlockRow
        errorMessage={errors.contractType?.message}
        label="契約種別"
        required
      >
        <InputSelect
          isInvalid={errors.contractType != null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`contractType`)}
        >
          <option value="sub_lease">サブリース</option>
          <option value="collection_agency">集金代行</option>
          <option value="general_management">一般管理</option>
          <option value="owned">自社所有</option>
          <option value="other">その他</option>
        </InputSelect>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.conditionFixedPeriod?.message}
        label="条件固定期間"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.conditionFixedPeriod != null}
              placeholder="12"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`conditionFixedPeriod`)}
            />
          </span>
          <span className={Styles.Unit}>ヶ月</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.conditionUpdateCycle?.message}
        label="条件変更周期"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.conditionUpdateCycle != null}
              placeholder="12"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`conditionUpdateCycle`)}
            />
          </span>
          <span className={Styles.Unit}>ヶ月</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.revisionPeriod?.message}
        label="改定期間"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.revisionPeriod != null}
              placeholder="12"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`revisionPeriod`)}
            />
          </span>
          <span className={Styles.Unit}>ヶ月</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.lowerLimitOfRentRevisionRate?.message}
        label="家賃改定割合の下限"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.lowerLimitOfRentRevisionRate != null}
              placeholder="12"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`lowerLimitOfRentRevisionRate`)}
            />
          </span>
          <span className={Styles.Unit}>％</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.lowerLimitOfRentRevisionRateDuration?.message}
        label="家賃改定割合の下限の有効年数"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.lowerLimitOfRentRevisionRateDuration != null}
              placeholder="10"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`lowerLimitOfRentRevisionRateDuration`)}
            />
          </span>
          <span className={Styles.Unit}>年</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.remittanceDateType?.message}
        label="送金日"
      >
        <InputSelect
          isInvalid={errors.remittanceDateType != null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`remittanceDateType`)}
        >
          <option value="">選択なし</option>
          <option value="last_day">月末</option>
          <option value="specify_date">指定日</option>
        </InputSelect>
      </FormBlockRow>

      {watch('remittanceDateType') === 'specify_date' && (
        <FormBlockRow
          errorMessage={errors.remittanceDateSpecify?.message}
          label="送金指定日"
        >
          <div className={Styles.InputWithUnitWrapper}>
            <span className={Styles.NumberInput}>
              <Input
                isInvalid={errors.remittanceDateSpecify != null}
                placeholder="10"
                type="number"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(`remittanceDateSpecify`)}
              />
            </span>
            <span className={Styles.Unit}>日</span>
          </div>
        </FormBlockRow>
      )}

      <FormBlockRow
        errorMessage={errors.remittanceStartDate?.message}
        label="送金開始日"
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void =>
            onChangeDate(date, 'remittanceStartDate')
          }
          selected={convertDateWithNull(watch('remittanceStartDate'))}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.numberOfManagedProperties?.message}
        label="管理戸数"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.numberOfManagedProperties != null}
              placeholder="1"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`numberOfManagedProperties`)}
            />
          </span>
          <span className={Styles.Unit}>戸</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.managementReportingMonth?.message}
        label="管理レポートの報告月"
      >
        <InputSelect
          isInvalid={errors.managementReportingMonth != null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`managementReportingMonth`)}
        >
          <option value="">選択なし</option>
          <option value="1">1月</option>
          <option value="2">2月</option>
          <option value="3">3月</option>
          <option value="4">4月</option>
          <option value="5">5月</option>
          <option value="6">6月</option>
          <option value="7">7月</option>
          <option value="8">8月</option>
          <option value="9">9月</option>
          <option value="10">10月</option>
          <option value="11">11月</option>
          <option value="12">12月</option>
        </InputSelect>
      </FormBlockRow>
    </FormBlock>
  );
};
