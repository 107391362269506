import { ParsedUrlQuery } from 'querystring';
import { ContractQuery } from '~/models/contractQuery';

export const convertUrlQueryToContractQuery = (
  query: DeepReadonly<ParsedUrlQuery>
): ContractQuery => {
  const contractQuery: ContractQuery = {};
  if (query == null) {
    return contractQuery;
  }
  return {
    ...(query.page ? { page: Number(query.page) } : {}),
    ...(query.propertyName ? { propertyName: String(query.propertyName) } : {}),
    ...(query.contractKey ? { contractKey: String(query.contractKey) } : {}),
    ...(query.startDateFrom
      ? { startDateFrom: String(query.startDateFrom) }
      : {}),
    ...(query.startDateTo ? { startDateTo: String(query.startDateTo) } : {}),
    ...(query.endDateFrom ? { endDateFrom: String(query.endDateFrom) } : {}),
    ...(query.endDateTo ? { endDateTo: String(query.endDateTo) } : {}),
    ...(query.managementReportingMonth
      ? {
          managementReportingMonth: String(query.managementReportingMonth)
        }
      : {}),
    ...(query.kensakukunForeignSystemKey
      ? {
          kensakukunForeignSystemKey: String(query.kensakukunForeignSystemKey)
        }
      : {}),
    ...(query.archived === 'true' ? { archived: true } : {}),
    ...(query.unrelatedRemoteProperty === 'true'
      ? { unrelatedRemoteProperty: true }
      : {})
  };
};
