import React, { ReactNode } from 'react';
import { TabMode } from '~/components/specifics/contracts/[id]/components/Container';
import clsx from 'clsx';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useUsingSeisanKanrikun } from '~/hooks/useUsingSeisanKanrikun';
import { ContractWithOwners } from '~/models/contract';
import { StatusLabelCollaborationLabel } from '~/components/common/StatusLabel/Collaboration/Label';
import { buildBukkenKanrikunUrl } from '~/utils/foreignOrigin';
import { ContractsCollaborationSeisanKanrikunTab } from '../Collaboration/SeisanKanrikun/Tab';
import Styles from './index.module.scss';

type TabProps = DeepReadonly<{
  isActive: boolean;
  disabled: boolean;
  onClick: () => void;
  children: string;
}>;

const ContractsPageTab: React.FC<TabProps> = ({
  isActive,
  disabled,
  onClick,
  children
}: TabProps) => (
  <div
    className={clsx(
      Styles.Tab,
      isActive && Styles.Active,
      disabled && Styles.Disabled
    )}
  >
    <button
      className={Styles.TabButton}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {children}
      <RiArrowRightSLine className={Styles.RightArrow} />
    </button>
  </div>
);

type Props = DeepReadonly<{
  currentTabMode: TabMode;
  handleChangeTabMode: (newTabMode: TabMode) => void;
  contract: ContractWithOwners;
}>;

type TabInfo = {
  tabMode: TabMode;
  caption: string;
  disabled: boolean;
};

export const ContractsPageTabs: React.FC<Props> = ({
  currentTabMode,
  handleChangeTabMode,
  contract
}: Props) => {
  const usingSeisanKanrikun = useUsingSeisanKanrikun();
  // archivedを元に、「更新・解約手続き」か「解約の解除」を出し分ける
  const tabs: TabInfo[] = [
    { tabMode: 'basic', caption: '契約情報', disabled: false },
    { tabMode: 'remitting', caption: '支払/請求', disabled: false },
    {
      tabMode: 'process',
      caption: contract.archived ? '解約の取り消し' : '更新・解約手続き',
      disabled: false
    },
    { tabMode: 'print', caption: '帳票出力', disabled: false },
    { tabMode: 'files', caption: 'ファイル', disabled: false }
  ];

  const renderTab = React.useCallback(
    (tab: Readonly<TabInfo>): ReactNode => (
      <ContractsPageTab
        key={tab.tabMode}
        disabled={tab.disabled}
        isActive={currentTabMode === tab.tabMode}
        onClick={(): void => handleChangeTabMode(tab.tabMode)}
      >
        {tab.caption}
      </ContractsPageTab>
    ),
    [handleChangeTabMode, currentTabMode]
  );

  return (
    <div className={Styles.Tabs}>
      {tabs.map(renderTab)}
      {usingSeisanKanrikun && (
        <>
          <StatusLabelCollaborationLabel
            externalLink={
              contract && contract.property
                ? buildBukkenKanrikunUrl(contract.property)
                : undefined
            }
            productKey="bkk"
            text="管理対象"
          />
          <ContractsCollaborationSeisanKanrikunTab contract={contract} />
        </>
      )}
    </div>
  );
};
